import axios from 'axios';
import { Listing, Picture } from 'models';
import { getAPIURL } from 'config/constants';

export async function getReviewerToken(token: string) {
  try {
    const config = { headers: { token } };
    const response = await axios.post(`${getAPIURL()}/retreiveTokenFor3D`, {}, config);
    const reviewerToken = response.data.token;
    if (reviewerToken) {
      return reviewerToken;
    }
  } catch (err) {
    console.log('[getReviewerToken] error: ', err);
  }
  return null;
}

export async function savePictureOrder(pictures: Picture[], token: string) {
  try {
    const reorderedPictures = pictures
      .map((pic, i) => {
        return { ...pic, new_index: i };
      })
      .filter((pic) => {
        return pic.new_index !== pic.picture_index;
      })
      .map((pic) => {
        return { ...pic, picture_index: pic.new_index };
      });

    const config = { headers: { token } };
    const params = { pictures: reorderedPictures };

    const response = await axios.post(`${getAPIURL()}/saveOrderIndex`, params, config);
    return response;
  } catch (err) {
    console.log('[savePictureOrder] error: ', err);
  }
  return null;
}

export async function publishListing(listing_id: number, unPublish: boolean, token: string) {
  try {
    const config = { headers: { token } };
    const params = { listing_id };
    const route = unPublish ? 'unpublishListing' : 'publishListing';
    const response = await axios.post(`${getAPIURL()}/${route}`, params, config);
    return response;
  } catch (err) {
    console.log('[publishListing] error: ', err);
  }
  return null;
}

export async function setPicturesVisibility(
  picture_ids: number[],
  _public: boolean,
  token: string,
) {
  try {
    const config = { headers: { token } };
    const params = { picture_ids, public: _public };
    const response = await axios.post(`${getAPIURL()}/updatePictureVisibility`, params, config);
    return response;
  } catch (err) {
    console.log('[setPicturesVisibility] error: ', err);
  }
  return null;
}

export async function updatePicturesVideoSelection(
  picture_ids: number[],
  is_selected: boolean,
  token: string,
) {
  try {
    const config = { headers: { token } };
    const params = { picture_ids, is_selected };
    const response = await axios.post(`${getAPIURL()}/updatePictureVideoSelection`, params, config);
    return response;
  } catch (err) {
    console.log('[updatePicturesVideoSelection] error: ', err);
  }
  return null;
}

export async function showWatermark(picture_ids: number[], show: boolean, token: string) {
  try {
    const config = { headers: { token } };
    const params = { picture_ids, show };
    const response = await axios.post(`${getAPIURL()}/showWatermark`, params, config);
    return response;
  } catch (err) {
    console.log('[showWatermark] error: ', err);
  }
  return null;
}

export async function updatePicturesType(
  picture_ids: number[],
  picture_service_offer_id: number,
  token: string,
) {
  try {
    const config = { headers: { token } };
    const params = { picture_ids, picture_service_offer_id };
    console.log('params: ', params);
    const response = await axios.post(`${getAPIURL()}/updatePicturesType`, params, config);
    return response;
  } catch (err) {
    console.log('[updatePicturesType] error: ', err);
  }
  return null;
}

export async function sendMediaEmailToBroker(listing_id: number, token: string) {
  try {
    const config = { headers: { token } };
    const params = { listing_id };
    const response = await axios.post(`${getAPIURL()}/sendMediaEmail`, params, config);
    return response;
  } catch (err) {
    console.log('[sendMediaEmailToBroker] error: ', err);
  }
  return null;
}

export async function resetDownload(picture_ids: number[], token: string) {
  try {
    const config = { headers: { token } };
    const params = { picture_ids };
    const response = await axios.post(`${getAPIURL()}/resetPictureDownload`, params, config);
    return response;
  } catch (err) {
    console.log('[resetDownload] error: ', err);
  }
  return null;
}

export async function setServiceOfferYoutubeVideoId(
  shooting_service_offer_id: number,
  youtube_video_id: string,
  token: string,
) {
  try {
    const config = { headers: { token } };
    const params = { shooting_service_offer_id, youtube_video_id };
    const response = await axios.post(
      `${getAPIURL()}/setServiceOfferYoutubeVideoId`,
      params,
      config,
    );
    return response;
  } catch (err) {
    console.log('[setServiceOfferYoutubeVideoId] error: ', err);
  }
  return null;
}

export async function clearMediaCache(urls: any[], token: string) {
  try {
    const config = { headers: { token } };
    const params = { urls };
    const response = await axios.post(`${getAPIURL()}/clearMediaCache`, params, config);
    return response;
  } catch (err) {
    console.log('[clearMediaCache] error: ', err);
  }
  return null;
}

export async function clearListingCache(listing_id: number, token: string) {
  try {
    const config = { headers: { token } };
    const params = { listing_id };
    const response = await axios.post(`${getAPIURL()}/purgeListingCache`, params, config);
    return response;
  } catch (err) {
    console.log('[clearListingCache] error: ', err);
  }
  return null;
}

export async function deleteDetailedFloorplan(
  listing_id: number,
  shooting_service_offer_id: number,
  token: string,
) {
  try {
    const config = { headers: { token } };
    const params = { listing_id, shooting_service_offer_id };
    const response = await axios.post(
      `${getAPIURL()}/deleteDetailedFloorplanMedia`,
      params,
      config,
    );
    return response;
  } catch (err) {
    console.log('[deleteDetailedFloorplan] error: ', err);
  }
  return null;
}

export async function remakeCubeMap(marker_id: number, listing_id: number, token: string) {
  try {
    const config = { headers: { token } };
    const params = { marker_id, listing_id };
    const response = await axios.post(`${getAPIURL()}/remakeCubeMap`, params, config);
    return response;
  } catch (error) {
    console.log('[remakeCubemap] error: ', error);
  }
}

export async function reprocessMarker(marker_id: number, listing_id: number, token: string) {
  try {
    const config = { headers: { token } };
    const marker_reset_params = { marker_id };
    let response = null;
    const reset_response = await axios.post(
      `${getAPIURL()}/resetMarkerState`,
      marker_reset_params,
      config,
    );
    if (reset_response.status === 200) {
      const remake_marker_params = { listing_id };
      response = await axios.post(
        `${getAPIURL()}/create3DProcessingJob`,
        remake_marker_params,
        config,
      );
    }
    return response;
  } catch (error) {
    console.log('[remakeCubemap] error: ', error);
  }
}

export async function createListing360Video(marker_infos: any, listing_id: number, token: string) {
  try {
    const config = { headers: { token } };
    const params = { marker_infos, listing_id };
    const response = await axios.post(`${getAPIURL()}/create360VideoJob`, params, config);
    return response;
  } catch (error) {
    console.log('[create360Video] error: ', error);
  }
}

export async function changeVideoVisibility(video_id: number, is_public: boolean, token: string) {
  try {
    const config = { headers: { token } };
    const params = { video_id, is_public };
    const response = await axios.put(`${getAPIURL()}/changeVideoVisibility`, params, config);
    return response;
  } catch (error) {
    console.log('[changeVideoVisibility] error: ', error);
  }
}

export async function changeShootingServiceOfferVisibility(
  shooting_service_offer_id: number,
  is_public: boolean,
  token: string,
) {
  try {
    const config = { headers: { token } };
    const params = { shooting_service_offer_id, is_public };
    const response = await axios.put(
      `${getAPIURL()}/changeShootingServiceOfferVisibility`,
      params,
      config,
    );
    return response;
  } catch (error) {
    console.log('[changeShootingServiceOfferVisibility] error: ', error);
  }
}
