import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { BrokerFavoriteListing, Broker, Listing } from 'models';
import {
  getPageInfosFromDomain,
  addFavoriteListings,
  fetchBrokerFavoriteListings,
  removeFavoriteListing,
  updateBrokerPublicPageInfos,
  verifyDomain,
  updateBrokerPortalBanner,
  updateBrokerPortalBannerProfile,
  updateBrokerPortalLogo,
  updateBrokerBackgroundImage,
  updateBrokerPortalSectionMedia,
  updateBrokerPortalClientPageMedia,
  updateListingOrderIndex,
  fetchDemoListings,
  fetchBrokerPortalListings,
  fetchServiceOfferDemoPictures,
  submitLeadRequest,
  fetchClientPageInfo,
} from 'apis';
import { MODAL, PORTAL_SECTIONS, HAUSVALET_ADMIN_PORTAL } from 'config/constants';
import { hideModal } from './app';

const initialState = {
  domain_name: '',
  favorite_listings: [],
  broker: {},
  broker_portal_infos: {},
  addListingsStatus: '',
  isDomainAvailable: true,
  isCustomDomainAvailable: true,
  savedInfoStatus: '',
  isDomainTaken: false,
  saveBgImageStatus: '',
  demo_listings: [],
  broker_portal_listings: [],
  saveLogoImageStatus: '',
  saveBannerImageStatus: '',
  saveBannerProfileImageStatus: '',
  saveFaviconStatus: '',
  no_domain_found: false,
  service_offer_demo_pictures: {},
  centris_listings: [],
  centris_listings_from_db: [],
  all_templates: [],
  sections: [],
  client_pages: [],
  broker_portal_infos_id: null,
  section: {},
  selectedSection: PORTAL_SECTIONS.BASIC_INFO.subSections.PERSONAL_INFO.key,
  selectedContent: null,
  editMode: false,
  contentFocus: false,
  currentSectionTab: 0,
  showSideBar: false,
  google_reviews: [],
  savedMediaStatus: '',
  currentSection: '',
  client_page_info: {},
  showAnimation: false,
};

export const personalPortalSlice = createSlice({
  name: 'personalPortal',
  initialState,
  reducers: {
    initialize: (state) => {
      state.domain_name = '';
      state.favorite_listings = [];
      state.broker = {};
      state.broker_portal_infos = {};
      state.addListingsStatus = '';
      state.isDomainAvailable = true;
      state.isCustomDomainAvailable = true;
      state.savedInfoStatus = '';
      state.isDomainTaken = false;
      state.saveBgImageStatus = '';
      state.demo_listings = [];
      state.broker_portal_listings = [];
      state.saveLogoImageStatus = '';
      state.saveBannerImageStatus = '';
      state.saveBannerProfileImageStatus = '';
      state.saveFaviconStatus = '';
      state.no_domain_found = false;
      state.service_offer_demo_pictures = {};
      state.centris_listings = [];
      state.centris_listings_from_db = [];
      state.all_templates = [];
      state.sections = [];
      state.client_pages = [];
      state.broker_portal_infos_id = null;
      state.section = {};
      state.selectedSection = PORTAL_SECTIONS.BASIC_INFO.subSections.PERSONAL_INFO.key;
      state.selectedContent = null;
      state.editMode = false;
      state.contentFocus = false;
      state.currentSectionTab = 0;
      state.showSideBar = false;
      state.google_reviews = [];
      state.savedMediaStatus = '';
      state.currentSection = '';
      state.client_page_info = {};
      state.showAnimation = false;
    },
    setFavoriteListings: (state, action) => {
      state.favorite_listings = action.payload;
    },
    setBroker: (state, action) => {
      state.broker = action.payload;
    },
    setBrokerPortalInfos: (state, action) => {
      state.broker_portal_infos = action.payload;
    },
    setAddListingsStatus: (state, action) => {
      state.addListingsStatus = action.payload;
    },
    removeListing: (state, action) => {
      state.favorite_listings = state.favorite_listings.filter((l: any) => {
        return l.listing.id !== action.payload;
      });
    },
    setVerifyDomainResult: (state, action) => {
      state.isDomainAvailable = action.payload;
    },
    setVerifyCustomDomainResult: (state, action) => {
      state.isCustomDomainAvailable = action.payload;
    },
    setSavedInfoStatus: (state, action) => {
      state.savedInfoStatus = action.payload;
    },
    setReorderedListings: (state, action) => {
      state.favorite_listings = action.payload;
    },
    setDomainTaken: (state, action) => {
      state.isDomainTaken = action.payload;
    },
    setSavedBgImageStatus: (state, action) => {
      state.saveBgImageStatus = action.payload;
    },
    setDemoListings: (state, action) => {
      state.demo_listings = action.payload;
    },
    setDomainName: (state, action) => {
      state.domain_name = action.payload;
    },
    setBrokerPortalListings: (state, action) => {
      state.broker_portal_listings = action.payload;
    },
    setSavedLogoImageStatus: (state, action) => {
      state.saveLogoImageStatus = action.payload;
    },
    setSavedBannerImageStatus: (state, action) => {
      state.saveBannerImageStatus = action.payload;
    },
    setSavedBannerProfileImageStatus: (state, action) => {
      state.saveBannerProfileImageStatus = action.payload;
    },
    setSavedFaviconStatus: (state, action) => {
      state.saveFaviconStatus = action.payload;
    },
    setNoDomainFound: (state, action) => {
      state.no_domain_found = action.payload;
    },
    setServiceOfferDemoPictures: (state, action) => {
      state.service_offer_demo_pictures = action.payload;
    },
    setCentrisListings: (state, action) => {
      state.centris_listings = action.payload;
    },
    setCentrisListingsFromDb: (state, action) => {
      state.centris_listings_from_db = action.payload;
    },
    setTemplates: (state, action) => {
      state.all_templates = action.payload;
    },
    addFavoriteListing: (state, action) => {
      state.favorite_listings = state.favorite_listings.concat(action.payload);
    },
    setSections: (state, action) => {
      state.sections = action.payload;
    },
    setBrokerPortalId: (state, action) => {
      state.broker_portal_infos_id = action.payload;
    },
    setSection: (state, action) => {
      state.section = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
    setSelectedContent: (state, action) => {
      state.selectedContent = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    setContentFocus: (state, action) => {
      state.contentFocus = action.payload;
    },
    updateSection: (state, action) => {
      state.section = action.payload;
    },
    onSelectContent: (state, action) => {
      const { e, contentKey } = action.payload;
      e.stopPropagation();
      if (contentKey !== state.selectedContent) {
        state.selectedContent = contentKey;
      }
    },
    onSelectSection: (state, action) => {
      const { e, sectionKey } = action.payload;
      e.stopPropagation();
      if (!state.contentFocus) {
        state.selectedContent = sectionKey;
      }
    },
    setCurrentSectionTab: (state, action) => {
      state.currentSectionTab = action.payload;
    },
    setShowSideBar: (state, action) => {
      state.showSideBar = action.payload;
    },
    setGoogleReviews: (state, action) => {
      state.google_reviews = action.payload;
    },
    setSavedMediaStatus: (state, action) => {
      state.savedMediaStatus = action.payload;
    },
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
    },
    setClientPages: (state, action) => {
      state.client_pages = action.payload;
    },
    setClientPageInfo: (state, action) => {
      state.client_page_info = action.payload;
    },
    setShowAnimation: (state, action) => {
      state.showAnimation = action.payload;
    },
  },
});

export const {
  initialize,
  setFavoriteListings,
  setBroker,
  setBrokerPortalInfos,
  setAddListingsStatus,
  removeListing,
  addFavoriteListing,
  setVerifyDomainResult,
  setVerifyCustomDomainResult,
  setSavedInfoStatus,
  setReorderedListings,
  setDomainTaken,
  setSavedBgImageStatus,
  setSavedLogoImageStatus,
  setSavedBannerImageStatus,
  setSavedBannerProfileImageStatus,
  setSavedFaviconStatus,
  setDemoListings,
  setDomainName,
  setBrokerPortalListings,
  setNoDomainFound,
  setServiceOfferDemoPictures,
  setCentrisListings,
  setCentrisListingsFromDb,
  setTemplates,
  setSections,
  setBrokerPortalId,
  setSection,
  setSelectedSection,
  setSelectedContent,
  setEditMode,
  setContentFocus,
  updateSection,
  onSelectContent,
  onSelectSection,
  setCurrentSectionTab,
  setShowSideBar,
  setGoogleReviews,
  setSavedMediaStatus,
  setCurrentSection,
  setClientPages,
  setClientPageInfo,
  setShowAnimation,
} = personalPortalSlice.actions;

export const fetchBrokerPublicPortalInfos = (
  token?: string,
  domain_name?: string,
  language?: string,
) => {
  return async (dispatch: Dispatch) => {
    const {
      broker,
      broker_favorite_listings,
      broker_portal_infos,
      no_domain_found,
      all_templates,
      centris_listings,
      centris_listings_from_db,
      google_reviews,
    } = await getPageInfosFromDomain(token, domain_name, language);
    if (no_domain_found) {
      dispatch(setNoDomainFound(no_domain_found));
    }
    if (broker) {
      dispatch(setBroker(broker));
    }
    if (domain_name === HAUSVALET_ADMIN_PORTAL && broker_favorite_listings) {
      dispatch(setFavoriteListings(broker_favorite_listings));
    }
    if (broker_portal_infos) {
      dispatch(setBrokerPortalInfos(broker_portal_infos));
      if ('id' in broker_portal_infos) {
        dispatch(setBrokerPortalId(broker_portal_infos.id));
      }
      if ('sections' in broker_portal_infos) {
        const { sections } = broker_portal_infos;
        const sectionsArray = sections as { section: { order_index: number } }[];
        const sortedSections = sectionsArray.slice().sort((a, b) => {
          return a.section.order_index - b.section.order_index;
        });
        dispatch(setSections(sortedSections));
      }
      if ('client_pages' in broker_portal_infos) {
        dispatch(setClientPages(broker_portal_infos.client_pages));
      }
    }
    if (all_templates) {
      dispatch(setTemplates(all_templates));
    }
    if (centris_listings) {
      dispatch(setCentrisListings(centris_listings));
    }
    if (centris_listings_from_db) {
      dispatch(setCentrisListingsFromDb(centris_listings_from_db));
    }
    if (google_reviews) {
      dispatch(setGoogleReviews(google_reviews));
    }
  };
};

export const addFavoriteListingsAsync = (
  token: string,
  listings: Array<Listing>,
  broker_id: string,
) => {
  return async (dispatch: Dispatch) => {
    const res = await addFavoriteListings(token, listings, broker_id);
    if (res && res.status === 200) {
      dispatch(setAddListingsStatus(res.status));
      dispatch(addFavoriteListing(listings));
      dispatch(hideModal(MODAL.ADD_NEW_FAVORITE_LISTING));
    }
  };
};

export const fetchFavoriteListingsAsync = (token: string, broker_id) => {
  return async (dispatch: Dispatch) => {
    const { broker_favorite_listings } = await fetchBrokerFavoriteListings(token, broker_id);
    if (broker_favorite_listings) {
      dispatch(setFavoriteListings(broker_favorite_listings));
    }
  };
};

export const removeFavoriteListingsAsync = (token: string, listing_id: string, broker_id) => {
  return async (dispatch: Dispatch) => {
    const res = await removeFavoriteListing(token, listing_id, broker_id);
    if (res && res.status === 200) {
      dispatch(removeListing(listing_id));
    }
  };
};

export const updateBrokerPublicPageInfosAsync = (
  token: string,
  is_domain_required: boolean,
  domain_name: string,
  custom_domain_name: string,
  bio_fr: string,
  bio_en: string,
  display_name: string,
  email: string,
  phone_number: number,
  FBPageID: number,
  pixelID: number,
  instagram: string,
  facebook: string,
  twitter: string,
  linkedin: string,
  youtube: string,
  tiktok: string,
  website: string,
  address: string,
  slogan_fr: string,
  slogan_en: string,
  template_id: number,
  template_style: any,
) => {
  return async (dispatch: Dispatch) => {
    const res = await updateBrokerPublicPageInfos(
      token,
      is_domain_required,
      domain_name,
      custom_domain_name,
      bio_fr,
      bio_en,
      display_name,
      email,
      phone_number,
      FBPageID,
      pixelID,
      instagram,
      facebook,
      twitter,
      linkedin,
      youtube,
      tiktok,
      website,
      address,
      slogan_fr,
      slogan_en,
      template_id,
      template_style,
    );
    if (res && res.status === 200) {
      dispatch(setSavedInfoStatus(200));
      const { broker, broker_portal_infos } = await getPageInfosFromDomain(token, null);
      if (broker && broker_portal_infos) {
        dispatch(setBroker(broker));
        dispatch(setBrokerPortalInfos(broker_portal_infos));
      }
    } else {
      dispatch(setSavedInfoStatus(500));
    }
    dispatch(setSavedInfoStatus(''));
  };
};

export const verifyDomainNameAsync = (token: string, domain_name: string, type: string) => {
  return async (dispatch: Dispatch) => {
    const res = await verifyDomain(token, domain_name, type);
    if (res && res.type === 'domain_name') {
      if (res.status === 200) {
        dispatch(setVerifyDomainResult(true));
      } else {
        dispatch(setVerifyDomainResult(false));
      }
    }
    if (res && res.type === 'custom_domain_name') {
      if (res.status === 200) {
        dispatch(setVerifyCustomDomainResult(true));
      } else {
        dispatch(setVerifyCustomDomainResult(false));
      }
    }
  };
};
export default personalPortalSlice.reducer;

export const updateBrokerPortalBannerAsync = (token: string, formdata: FormData) => {
  return async (dispatch: Dispatch) => {
    const res = await updateBrokerPortalBanner(token, formdata);
    if (res === 200) {
      dispatch(setSavedBannerImageStatus(200));
    } else {
      dispatch(setSavedBannerImageStatus(500));
    }
    dispatch(setSavedBannerImageStatus(''));
  };
};

export const updateBrokerPortalBannerProfileAsync = (token: string, formdata: FormData) => {
  return async (dispatch: Dispatch) => {
    const res = await updateBrokerPortalBannerProfile(token, formdata);
    if (res === 200) {
      dispatch(setSavedBannerProfileImageStatus(200));
    } else {
      dispatch(setSavedBannerProfileImageStatus(500));
    }
    dispatch(setSavedBannerProfileImageStatus(''));
  };
};

export const updateBrokerPortalLogoAsync = (token: string, formdata: FormData) => {
  return async (dispatch: Dispatch) => {
    const res = await updateBrokerPortalLogo(token, formdata);
    if (res === 200) {
      dispatch(setSavedLogoImageStatus(200));
    } else {
      dispatch(setSavedLogoImageStatus(500));
    }
    dispatch(setSavedLogoImageStatus(''));
  };
};
export const updateBrokerPortalBackgroundAsync = (token: string, formdata: FormData) => {
  return async (dispatch: Dispatch) => {
    const res = await updateBrokerBackgroundImage(token, formdata);
    if (res === 200) {
      dispatch(setSavedBgImageStatus(200));
      const { broker, broker_favorite_listings, broker_portal_infos } =
        await getPageInfosFromDomain(token, null);
      if (broker && broker_favorite_listings && broker_portal_infos) {
        dispatch(setBroker(broker));
        dispatch(setFavoriteListings(broker_favorite_listings));
        dispatch(setBrokerPortalInfos(broker_portal_infos));
      }
    } else {
      dispatch(setSavedBgImageStatus(500));
    }
    dispatch(setSavedBgImageStatus(''));
  };
};

export const updateBrokerFaviconAsync = (token: string, formdata: FormData) => {
  return async (dispatch: Dispatch) => {
    const res = await updateBrokerPortalSectionMedia(token, formdata);
    if (res === 200) {
      dispatch(setSavedFaviconStatus(200));
    } else {
      dispatch(setSavedFaviconStatus(500));
    }
    dispatch(setSavedFaviconStatus(''));
  };
};

export const updateFavoriteListingsOrderAsync = (token: string, reordered_listings) => {
  return async (dispatch: Dispatch) => {
    reordered_listings.map((l, index) => {
      l.order_index = index;
      return null;
    });
    const res = await updateListingOrderIndex(token, reordered_listings);
    if (res && res.status === 200) {
      dispatch(setReorderedListings(reordered_listings));
    }
  };
};

export const fetchDemoListingsAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const { demo_listings } = await fetchDemoListings(token);
    if (demo_listings) {
      dispatch(setDemoListings(demo_listings));
    }
  };
};

export const fetchBrokerPortalListingsAsync = (token: string) => {
  return async (dispatch: Dispatch) => {
    const { listings } = await fetchBrokerPortalListings(token);
    if (listings) {
      dispatch(setBrokerPortalListings(listings));
    }
  };
};

export const fetchServiceOfferDemoPicturesAsync = () => {
  return async (dispatch: Dispatch) => {
    const { demo_pictures } = await fetchServiceOfferDemoPictures();
    if (demo_pictures) {
      dispatch(setServiceOfferDemoPictures(demo_pictures));
    }
  };
};
export const submitLeadRequestAsync = (formData: any) => {
  return async (dispatch: Dispatch) => {
    const res = await submitLeadRequest(formData);
    if (res && res.status === 200) {
      return true;
    }
    return false;
  };
};

export const updateBrokerPortalSectionMediaAsync = (token: string, formdata: FormData) => {
  return async (dispatch: Dispatch) => {
    const res = await updateBrokerPortalSectionMedia(token, formdata);
    if (res.status === 200) {
      dispatch(setSavedMediaStatus(200));
    } else {
      dispatch(setSavedMediaStatus(500));
    }
    dispatch(setSavedMediaStatus(''));
  };
};

export const updateBrokerPortalClientPageMediaAsync = (token: string, formdata: FormData) => {
  return async (dispatch: Dispatch) => {
    const res = await updateBrokerPortalClientPageMedia(token, formdata);
    if (res.status === 200) {
      dispatch(setSavedMediaStatus(200));
    } else {
      dispatch(setSavedMediaStatus(500));
    }
    dispatch(setSavedMediaStatus(''));
  };
};

export const fetchClientPageInfoAsync = (link: string) => {
  return async (dispatch: Dispatch) => {
    const { client_page_info } = await fetchClientPageInfo(link);
    if (client_page_info) {
      dispatch(setClientPageInfo(client_page_info));
      if (client_page_info.broker_portal_infos) {
        dispatch(setBrokerPortalInfos(client_page_info.broker_portal_infos));
      }
      if (client_page_info.google_reviews) {
        dispatch(setGoogleReviews(client_page_info.google_reviews));
      }
    }
  };
};
