export const HEADER_BASE_URL = 'https://media.hausvalet.ca';
export const BASE_URL = 'https://hausvalet.ca';
export const VIEWER_BASE_URL = 'https://visit.hausvalet.ca';
export const VIEWER_BASE_URL_REVIEW = 'https://visite.hausvalet.ca';
export const VIEWER_BASE_URL_DEV = 'https://devvisite.hausvalet.ca';
export const VIEWER_BASE_URL_STAGING = 'https://stagingvisit.hausvalet.ca';
export const API_BASE_URL = 'https://api.hauslifenetwork.com/hb/api';
export const API_BASE_URL_DEV = 'https://api-dev.hauslifenetwork.com/hb/api';
export const API_BASE_URL_STAGING = 'https://api-staging.hauslifenetwork.com/hb/api';
export const IFrame_URLS = [
  'https://subscriptions.zoho.com/subscribe/a71017da6dc081b6e24a0c54eb5b609679bc88ff48c14354ad482bf477cf719e/GRATUIT',
  'https://subscriptions.zoho.com/subscribe/a71017da6dc081b6e24a0c54eb5b609679bc88ff48c14354ad482bf477cf719e/Haus-2021-SOLOM2M',
  'https://subscriptions.zoho.com/subscribe/a71017da6dc081b6e24a0c54eb5b609679bc88ff48c14354ad482bf477cf719e/HAUS-2021-COMM',
];
export const BILLING_PORTAL_URLS = 'https://facturation.hausvalet.ca/portal/hausvalet';
export const CDN_URL = 'https://cdn.hauslifenetwork.com/';

export const MOVING_WALDO_FRENCH =
  'https://www.movingwaldo.com/fr/obtenir-une-soumission-de-demenageurs/?utm_source=hausvalet&utm_medium=portal&utm_campaign=affiliate';
export const MOVING_WALDO_ENGLISH =
  'https://www.movingwaldo.com/canada-movers-form/?utm_source=hausvalet&utm_medium=portal&utm_campaign=affiliate';

export function getAPIURL() {
  if (window.location.href.indexOf('localhost') > -1) return API_BASE_URL_DEV;
  if (
    window.location.href.indexOf('devportail') > -1 ||
    window.location.href.indexOf('devportal') > -1
  ) {
    return API_BASE_URL_DEV;
  }
  if (
    window.location.href.indexOf('stagingportail') > -1 ||
    window.location.href.indexOf('stagingportal') > -1 ||
    window.location.href.indexOf('baudinet') > -1
  ) {
    return API_BASE_URL_STAGING;
  }
  // TODO: change back to API_BASE_URL
  return API_BASE_URL_DEV;
}

export function getViewerURL() {
  if (window.location.href.indexOf('localhost') > -1) return VIEWER_BASE_URL_DEV;
  if (
    window.location.href.indexOf('devportail') > -1 ||
    window.location.href.indexOf('devportal') > -1
  ) {
    return VIEWER_BASE_URL_DEV;
  }
  if (
    window.location.href.indexOf('stagingportail') > -1 ||
    window.location.href.indexOf('stagingportal') > -1
  ) {
    return VIEWER_BASE_URL_STAGING;
  }
  return VIEWER_BASE_URL;
}

export function getAdminPortalURL() {
  if (window.location.href.indexOf('localhost') > -1) return 'https://devadmin.hausvalet.ca';
  if (
    window.location.href.indexOf('devportail') > -1 ||
    window.location.href.indexOf('devportal') > -1
  ) {
    return 'https://devadmin.hausvalet.ca';
  }
  if (
    window.location.href.indexOf('stagingportail') > -1 ||
    window.location.href.indexOf('stagingportal') > -1
  ) {
    return 'https://stagingadmin.hausvalet.ca';
  }
  return 'https://admin.hausvalet.ca';
}

export function isDev() {
  const devPath = ['localhost', 'devportail', 'devportal'];
  return devPath.some((path) => {
    return window.location.href.indexOf(path) > -1;
  });
}
