import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import CookieConsent from 'react-cookie-consent';
import { reactLocalStorage } from 'reactjs-localstorage';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { Typography, Link, IconButton, Drawer, Switch } from '@material-ui/core';
import { HvButton } from 'components';
import { COLOR, KEYS, COOKIES_OPTIONS_KEYS } from 'config/constants';

const useStyles = makeStyles({
  cookies: {
    height: 30,
    width: 30,
  },
  title: {
    fontFamily: 'FordAntenna-SemiBold',
    fontSize: 18,
    marginBottom: 15,
  },
  cookiesText: {
    fontSize: 14,
  },
  declineButton: {
    marginRight: 20,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  manageButton: {
    marginRight: 10,
    marginTop: 10,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  acceptButton: {
    marginRight: 10,
  },
  buttonsGroup: {
    marginRight: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  optionsContent: {
    padding: 20,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  optionTitleContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  optionTitle: {
    marginLeft: 5,
  },
  description: {
    fontSize: 12,
  },
  active: {
    fontSize: 12,
  },
  switch: {
    '&>.MuiSwitch-colorPrimary': {
      color: COLOR.GREEN,
    },
    '&>.MuiSwitch-colorPrimary.Mui-checked': {
      color: 'white',
      backgroundColor: COLOR.GREEN,
    },
    '&>.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      color: 'white',
      backgroundColor: COLOR.GREEN,
    },
    '&>.MuiSwitch-colorSecondary.Mui-checked': {
      color: COLOR.WHITE,
    },
    '&>.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: COLOR.GREEN,
    },
    '&>.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      opacity: 1,
    },
    '&>.MuiSwitch-track': {
      backgroundColor: COLOR.DARK_GREY,
    },
    marginRight: 5,
    marginLeft: 5,
  },
  root: {
    width: 46,
    height: 26,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: 'white',
      '& + $track': {
        opacity: 1,
        border: '6px solid #fff',
      },
    },
    '&$focusVisible $thumb': {
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    opacity: 1,
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginTop: 20,
  },
  descriptionRow: {
    marginTop: 20,
    width: '80%',
  },
});

interface Props {
  style?: any;
  is_white_text?: boolean;
  secondary_bg_text_color?: string;
}

function Cookies(props: Props) {
  const { style, is_white_text, secondary_bg_text_color } = props;
  const intl = useIntl();
  const classes = useStyles();

  const cookies = reactLocalStorage.getObject(KEYS.COOKIES_CONSENT);
  const [cookiesVisible, setCookiesVisible] = useState(isEmpty(cookies));
  const [displayOptions, setDisplayOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const fmtCookies = intl.formatMessage({ id: 'portal.cookies' });
  const fmtAccept = intl.formatMessage({ id: 'portal.accept' });
  const fmtAcceptAll = intl.formatMessage({ id: 'portal.acceptAll' });
  const fmtManagePreference = intl.formatMessage({ id: 'portal.managePreference' });
  const fmtEssentialCookiesTitle = intl.formatMessage({ id: 'portal.essentialCookiesTitle' });
  const fmtEssentialCookies = intl.formatMessage({ id: 'portal.essentialCookies' });
  const fmtPerformanceCookiesTitle = intl.formatMessage({ id: 'portal.performanceCookiesTitle' });
  const fmtPerformanceCookies = intl.formatMessage({ id: 'portal.performanceCookies' });
  const fmtFunctionalCookiesTitle = intl.formatMessage({ id: 'portal.functionalCookiesTitle' });
  const fmtFunctionalCookies = intl.formatMessage({ id: 'portal.functionalCookies' });
  const fmtSavePreference = intl.formatMessage({ id: 'portal.savePreferences' });
  const fmtAlwaysActive = intl.formatMessage({ id: 'portal.alwaysActive' });
  const fmtDecline = intl.formatMessage({ id: 'portal.decline' });

  const primary_color = style?.primary_color ? style?.primary_color : COLOR.LIGHT_BLUE;
  const secondary_color = style?.secondary_color ? style?.secondary_color : COLOR.LIGHT_BLUE;
  const background_color = style?.header_bg_color ? style?.header_bg_color : COLOR.LIGHT_BLUE;

  const defaultCookiesOptions = [
    {
      key: COOKIES_OPTIONS_KEYS.ESSENTIAL,
      title: fmtEssentialCookiesTitle,
      description: fmtEssentialCookies,
      is_selected: true,
    },
    {
      key: COOKIES_OPTIONS_KEYS.FUNCTIONAL,
      title: fmtFunctionalCookiesTitle,
      description: fmtFunctionalCookies,
      is_selected: cookies[COOKIES_OPTIONS_KEYS.FUNCTIONAL] || false,
    },
    {
      key: COOKIES_OPTIONS_KEYS.PERFORMANCE,
      title: fmtPerformanceCookiesTitle,
      description: fmtPerformanceCookies,
      is_selected: cookies[COOKIES_OPTIONS_KEYS.PERFORMANCE] || false,
    },
  ];
  const [cookiesOptions, setCookiesOptions] = useState(defaultCookiesOptions);

  const onClickAccept = () => {
    setCookiesVisible(false);
  };

  const getCookieValue = (key) => {
    const foundCookie = cookiesOptions.find((option) => {
      return option.key === key;
    });
    if (foundCookie) {
      return foundCookie.is_selected;
    }
    return false;
  };

  const onClickAcceptAll = () => {
    const updatedCookiesOptions = cookiesOptions.map((option, i) => {
      return { ...option, is_selected: true };
    });
    setCookiesOptions(updatedCookiesOptions);
    const cookieObject = {
      functional: true,
      performance: true,
    };
    reactLocalStorage.setObject(KEYS.COOKIES_CONSENT, cookieObject);
    setDisplayOptions(false);
    setCookiesVisible(false);
  };

  const onClickManage = () => {
    setDisplayOptions(true);
  };

  const onClickExpand = (option) => {
    if (option === selectedOption) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };

  const handleSwitchChange = (e, key) => {
    const updatedCookiesOptions = cookiesOptions.map((option, i) => {
      if (option.key === key) {
        return { ...option, is_selected: e.target.checked };
      }
      return option;
    });
    setCookiesOptions(updatedCookiesOptions);
  };

  const checkIfSelected = (key) => {
    const foundOption = cookiesOptions.find((option, i) => {
      return key === option.key;
    });
    if (foundOption) {
      return foundOption.is_selected;
    }
    return false;
  };

  const onClickDecline = () => {
    const cookieObject = {
      functional: false,
      performance: false,
    };
    reactLocalStorage.setObject(KEYS.COOKIES_CONSENT, cookieObject);
    setCookiesVisible(false);
  };

  const onClickSave = () => {
    const cookieObject = {
      functional: getCookieValue(COOKIES_OPTIONS_KEYS.FUNCTIONAL),
      performance: getCookieValue(COOKIES_OPTIONS_KEYS.PERFORMANCE),
    };
    reactLocalStorage.setObject(KEYS.COOKIES_CONSENT, cookieObject);
    setDisplayOptions(false);
    setCookiesVisible(false);
  };

  const buttons = () => {
    return (
      <div className={classes.buttonsGroup}>
        <div className={classes.row}>
          <Link
            style={{ color: is_white_text ? primary_color : COLOR.WHITE }}
            className={classes.declineButton}
            onClick={onClickDecline}
          >
            <Typography>{fmtDecline}</Typography>
          </Link>
          <HvButton
            className={classes.acceptButton}
            style={{
              backgroundColor: secondary_color,
              color: secondary_bg_text_color,
            }}
            onClick={onClickAcceptAll}
          >
            <Typography>{fmtAccept}</Typography>
          </HvButton>
        </div>
        <Link
          style={{ color: is_white_text ? primary_color : COLOR.WHITE }}
          className={classes.manageButton}
          onClick={onClickManage}
        >
          <Typography>{fmtManagePreference}</Typography>
        </Link>
      </div>
    );
  };

  const options = () => {
    return (
      <Drawer
        open={displayOptions}
        anchor='bottom'
        onClose={() => {
          return setDisplayOptions(false);
        }}
      >
        <div
          className={classes.optionsContent}
          style={{ background: background_color, color: secondary_bg_text_color }}
        >
          <Typography className={classes.title}>{fmtManagePreference}</Typography>
          {cookiesOptions.map((option, index) => {
            return (
              <div key={index}>
                <div className={classes.row}>
                  <div className={classes.optionTitleContent}>
                    <IconButton
                      onClick={() => {
                        return onClickExpand(option.key);
                      }}
                      style={{ color: secondary_bg_text_color }}
                    >
                      {selectedOption === option.key ? (
                        <ExpandLessRoundedIcon />
                      ) : (
                        <ExpandMoreRoundedIcon />
                      )}
                    </IconButton>
                    <div>
                      <Typography className={classes.optionTitle}>{option.title}</Typography>
                      {selectedOption === option.key ? (
                        <div className={classes.descriptionRow}>
                          <Typography className={classes.description}>
                            {option.description}
                          </Typography>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {option.key === COOKIES_OPTIONS_KEYS.ESSENTIAL ? (
                    <Typography className={classes.active}>{fmtAlwaysActive}</Typography>
                  ) : (
                    <Switch
                      className={classes.switch}
                      checked={checkIfSelected(option.key)}
                      onChange={(e) => {
                        return handleSwitchChange(e, option.key);
                      }}
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
          <div className={classes.alignRight}>
            <HvButton
              className={classes.acceptButton}
              style={{
                backgroundColor: secondary_color,
                color: secondary_bg_text_color,
              }}
              onClick={onClickAcceptAll}
            >
              <Typography>{fmtAcceptAll}</Typography>
            </HvButton>
            <HvButton
              style={{
                backgroundColor: secondary_color,
                color: secondary_bg_text_color,
              }}
              onClick={onClickSave}
            >
              {fmtSavePreference}
            </HvButton>
          </div>
        </div>
      </Drawer>
    );
  };

  return (
    <div>
      <CookieConsent
        style={{
          background: background_color,
          flexDirection: 'row',
          display: 'flex',
          padding: '10px 20px',
          borderTop: `1px solid ${COLOR.BLACK}`,
          color: COLOR.WHITE,
        }}
        buttonStyle={{
          color: COLOR.BLACK,
          fontSize: '13px',
          padding: '5px 10px',
          borderRadius: 8,
          marginRight: 10,
        }}
        visible={cookiesVisible ? 'show' : 'hidden'}
        ButtonComponent={buttons}
        expires={1}
      >
        <Typography className={classes.title}>Cookies</Typography>
        <Typography className={classes.cookiesText}>{fmtCookies}</Typography>
      </CookieConsent>
      {options()}
    </div>
  );
}

export default Cookies;
