import { SUBSCRIPTION } from 'config/constants';
import Profile from './Profile';
import BrokerCentrisData from './BrokerCentrisData';
import BrokerFranchise from './BrokerFranchise';
import BrokerPortalWebsiteMapping from './BrokerPortalWebsiteMapping';

class Broker {
  id: number;

  firstname: string;

  lastname: string;

  password: string;

  backup_broker_id: number;

  banner_id: number;

  billable_km: boolean;

  broker_code: string;

  broker_id: number;

  colors: string;

  cover_url: string;

  created_at: string;

  custom_title: string;

  centrisData: BrokerCentrisData;

  depot_id: number;

  email: string;

  firebase_token: string;

  first_login: boolean;

  franchise_id: number;

  franchise: BrokerFranchise;

  gf_clause: boolean;

  hdr_style: object;

  ios_device_token: string;

  language: string;

  last_seen_at: string;

  notification_email: string[] = [];

  notification_phone_number: string[] = [];

  notifications_enabled: boolean;

  oaciq_code: string;

  office_phone_number: string;

  opted_in_centris_data: boolean;

  part_time: boolean;

  phone_number: string;

  picture_url: string;

  pin: string;

  resetpasswordexpires: string;

  resetpasswordtoken: string;

  role: number;

  salutation: string;

  subscription_id: number;

  suspended: boolean;

  team_id: number;

  title_num: number;

  zendesk_id: number;

  zoho_contact_id: number;

  pixel_id: string;

  broker_facebook_business_page?: any;

  logo_url: string;

  broker_image: string;

  note_about_broker: string;

  centris_data_status: number;

  broker_portal_website?: BrokerPortalWebsiteMapping;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }

  get fullName() {
    return `${this.firstname} ${this.lastname}`;
  }

  get isPremium(): boolean {
    return (
      this.subscription_id === SUBSCRIPTION.application ||
      this.subscription_id === SUBSCRIPTION.plateforme
    );
  }

  get completedCentrisDataTransfer(): boolean {
    return (
      this.centris_data_status === Profile.CENTRIS_DATA_STATUS.SIGNED.VALUE ||
      this.centris_data_status === Profile.CENTRIS_DATA_STATUS.COMPLETED.VALUE
    );
  }

  get centrisPhoneNumber() {
    if (this.centrisData && this.centrisData.main_phone) {
      const { main_phone } = this.centrisData;
      return main_phone;
    }
    return '';
  }

  get centrisWebsite() {
    if (this.centrisData && this.centrisData.website) {
      const { website } = this.centrisData;
      return website;
    }
    return '';
  }

  get centrisOfficeAddress() {
    if (this.centrisData && this.centrisData.office) {
      const { office } = this.centrisData;
      const { city, civic_number, postalcode, street } = office;
      return `${civic_number} ${street}, ${city}, ${postalcode}`;
    }
    return '';
  }

  get centrisPhotoUrl() {
    if (this.centrisData && this.centrisData.photo_url) {
      const { photo_url } = this.centrisData;
      return photo_url;
    }
    return '';
  }

  get centrisCompanyLogoUrl() {
    if (this.centrisData && this.centrisData.office && this.centrisData.office.logo_url) {
      const { office } = this.centrisData;
      const { logo_url } = office;
      return logo_url;
    }
    return '';
  }

  get portalWebsite() {
    if (this.broker_portal_website && this.broker_portal_website.website) {
      const { website } = this.broker_portal_website;
      const { type } = website;
      return type;
    }
    return null;
  }
}

export default Broker;
